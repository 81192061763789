/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue').default;

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

// Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// const app = new Vue({
//     el: '#app',
// });
// Subir scroll
window.addEventListener("scroll", function () {
    if ($(this).scrollTop() > 50) {
        $(".subir").addClass("active");
    } else {
        $(".subir").removeClass("active");
    }
});

document.addEventListener("DOMContentLoaded", () => {
    // Datatables
    $('.datatable').DataTable({
        language: {
            url: "http://cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json",
        },
    });

    // Temporizador para cerrar alert
    window.setInterval(function () {
        $("#alert-main").delay(3000);
        $("#alert-main").slideUp();
    }, 1000);

    // Abrir/Cerrar sidebar
    if ($("#menu-toggle").length) {
        $("#menu-toggle").on("click", () => {
            $("#wrapper").toggleClass("toggled");
        });
    }

    // Cargar carrito en sidebar
    if ($("#carrito-productos").length) {
        listadoCarrito();
    }

    if ($("#deseos-productos").length) {
        listadoDeseos();
    }

    // Habilitar tooltips
    $('[data-toggle="tooltip"]').tooltip();

    // Consultar si el usuario acepto las cookies antes para no volver a preguntar
    $.ajax({
        url: `/api/aceptar-cookies/consultar`,
        dataType: "JSON",
        success: function(success_data) {
            if (success_data == false) {
                $("#aviso-cookies").removeClass("d-none");
            }
        },
        error: function(error_data) {
            mostrarAlerta("Ha ocurrido un error", "danger", error_data);
        }
    });
});
